<template>
    <div class="follow">
        <form class="search-haed" action="javascript:void 0" @submit.prevent="query">
            <div class="search">
                <div class="iconfont iconsearch"></div>
                <input  type="search" placeholder="请输入用户昵称" v-model="keyword" />
                <div class="chongzhi" @click="setReset()" v-if="listshow" >重置</div>
            </div>
        </form>
        <Tabs v-model="activeName" title-active-color="#000" v-on:click="Loadmore()">
                    <tab  title="推荐" name="a"></tab>
                    <tab  title="关注" name="b"></tab>
                    <tab  title="粉丝" name="c"></tab>
        </Tabs>
        <div class="tabs-content">
            <div v-if="activeName=='a'" style="background-color: rgb(245, 245, 245);">
                <!-- <img width="100%" :src="require('@assets/images/noTuijian.png')" /> -->
                <div class="rec-screen">
                    <div class="rec-screen-xingzuo">
                        <Cell is-link @click="selectScenes">
                         
                         <div class="Ellipses1">
                             <span class="cc">星座</span>
                                <span v-for="(item, index) in result" :key="index" class="tt">{{item.name}}</span>
                         </div>
                         </Cell>
                         <Popup v-model="showScenes" position="bottom">
                            <Picker show-toolbar title="选择星座" :columns="columnsScenes" value-key="name" @confirm="onConfirm2" @cancel="onCancel" />
                        <div class="CheckboxGroup">
                            <CheckboxGroup v-model="result">
                                <CellGroup>
                                    <Cell
                                    v-for="(item, index) in columnsScenes"
                                    clickable
                                    :key="item"
                                    :title="item.name"
                                    @click="toggle(index)"
                                    >
                                    <template #right-icon>
                                        <Checkbox :name="item" ref="checkboxes" />
                                    </template>
                                    </Cell>
                                </CellGroup>
                            </CheckboxGroup>
                        </div>
                    </Popup>
                    </div>
                    <div class="rec-screen-city">
                        <Cell is-link @click="selectScenes">
                         
                         <div class="Ellipses1">
                             <span class="cc">城市</span>
                                <span class="tt">{{city}}</span>
                         </div>
                         </Cell>
                         <Popup v-model="showCity" position="bottom">
                            <Picker show-toolbar title="选择城市" :columns="columnsScenes" value-key="name" @confirm="onConfirm" @cancel="onCancel" />
                        </Popup>
                    </div>
                </div>
                <div class="res-list">

                </div>
            </div>
            <div v-if="activeName=='b'">
                <Follow v-model="folist" :List="follow.folist"></Follow>
                <div class="Loading" v-if="follow.loading">
                    <Loading size="24px">加载中...</Loading>
                </div>
                <div class="Loading" v-if="follow.finished">没有更多了~</div>
            </div>
            <div v-if="activeName=='c'" >
                <Follow v-model="falist" :List="fans.falist"></Follow>
                <div class="Loading" v-if="fans.loading">
                    <Loading size="24px">加载中...</Loading>
                </div>
                <div class="Loading" v-if="fans.finished">没有更多了~</div>
            </div>
        </div>
        
    </div>
</template>

<script>
    import Follow from "./components/Follow";
    import { getfollowList } from "@api/tasting";
    import { Icon, Toast, Tab, Tabs ,Loading, Cell, Popup, Picker, CheckboxGroup, CellGroup, Checkbox } from "vant";
    export default {
        name: "followlist",
        data: function() {
            return {
                activeName:'a',
                keyword:'',
                listshow:false,
                type:1,
                follow:{
                    uid:0,
                    page:1,
                    limit:15,
                    type:1,//关注列表1 粉丝列表0
                    finished:false,
                    loading:false,
                    folist:[]
                },
                fans:{
                    uid:0,
                    page:1,
                    limit:15,
                    type:0,//关注列表1 粉丝列表0
                    finished:false,
                    loading:false,
                    falist:[]
                },
                result: [],
                yxresult: [],
                showCity:false,
                city:'',
                showScenes: false, //星座弹窗
                columnsScenes: [
                    {id:0,name:'全部'},
                    {id:1,name:'射手座'},
                    {id:2,name:'水瓶座'},
                    {id:3,name:'双鱼座'},
                    {id:4,name:'白羊座'},
                    {id:5,name:'金牛座'},
                    {id:6,name:'双子座'},
                    {id:7,name:'巨蟹座'},
                    {id:8,name:'处女座'},
                    {id:9,name:'狮子座'},
                    {id:10,name:'天蝎座'},
                    {id:11,name:'天秤座'},
                    {id:12,name:'摩羯座'},
                ], //星座列表
            
            };
        },
        components: {
            Icon, Toast, Tab, Tabs ,Follow ,Loading, Cell, Popup, Picker, CheckboxGroup, CellGroup, Checkbox
        },
        created: function() {
            this.activeName=this.$route.query.active;
        }, 
        mounted: function() {
            this.Loadmore();
            var that=this;
            window.addEventListener("scroll", function(event) {
                var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                if(document.documentElement.scrollHeight == document.documentElement.clientHeight + scrollTop) {
                    that.Loadmore();
                }
            });
        },
        methods: {
            query(){
                this.getKeyword();
            },
            setdata(){
                this.follow.loading = false;
                this.follow.finished = false;
                this.follow.page = 1;
                this.follow.limit = 15;
                this.follow.folist=[];
                this.fans.loading = false;
                this.fans.finished = false;
                this.fans.page = 1;
                this.fans.limit = 15;
                this.fans.falist=[];
            },
            setReset(){
                this.listshow = false;
                this.keyword = '';
                Toast.loading({
                    duration: 0,
                    message: '重置中...',
                    forbidClick: true,
                });
                if(this.activeName=="a"){
                }else if(this.activeName=="b"){
                    this.type = 1;
                }else if(this.activeName=="c"){
                    this.type = 0;    
                }
                this.setdata();
                this.Loadmore();
                Toast.clear();
                //this.show = false;
            },
            Loadmore: function() {
                if(this.activeName=="a"){
                    return;
                }else if(this.activeName=="b"){
                    this.type = 1;
                    if(this.follow.loading) return;
                    if(this.follow.finished) return;
                    this.followList();
                }else if(this.activeName=="c"){
                    this.type = 0;
                    if(this.fans.loading) return;
                    if(this.fans.finished) return;
                    this.followListf();
                }
                
            },
           followList:function(){
               this.follow.loading = true;
               getfollowList({
                   uid:this.follow.uid,//别人的uid
                   page:this.follow.page,
                   limit:this.follow.limit,
                   type:this.follow.type,
                   keyword:this.keyword,
               }).then(res => {
                   var listdata = res.data.list;
                    listdata.forEach((item)=>{
                        this.follow.folist.push(item);
                    })
                    this.follow.loading = false;
                    this.follow.page++;
                    if(listdata.length<15){
                        this.follow.finished = true;
                    }
               }).catch(err => {
                    this.follow.loading = false;
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            followListf:function(){
                this.fans.loading = true;
                getfollowList({
                    uid:this.fans.uid,//别人的uid
                    page:this.fans.page,
                    limit:this.fans.limit,
                    type:this.fans.type,
                    keyword:this.keyword,
                }).then(res => {
                    var listdata = res.data.list;
                        listdata.forEach((item)=>{
                            this.fans.falist.push(item);
                        })
                        this.fans.loading = false;
                        this.fans.page++;
                        if(listdata.length<15){
                            this.fans.finished = true;
                        }
                }).catch(err => {
                    this.fans.loading = false;
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            getKeyword(){
                let uids = 0;
                if(this.type=1)
                    uids = this.follow.uid;
                else
                     uids = this.fans.uid;
                getfollowList({
                    uid:uids,//别人的uid
                    type:this.type,
                    keyword:this.keyword,
                }).then(res => {
                    this.setdata();
                    var listdata = res.data.list;
                    if(this.type){
                        this.follow.folist = listdata;
                    }else{
                        this.fans.falist = listdata;
                    }
                    this.listshow = true;
                }).catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            onConfirm(){
                this.showCity = false;
                this.city = '广州市';
            },
            onConfirm2(value, index) {
                this.showScenes = false;
                this.yxresult=[];
                this.result.forEach(item=>{
                    this.yxresult.push(item);
                })
            },
            onCancel() {
                this.showCity = false;
                this.showScenes = false;
            },
            toggle(index) {
                this.$refs.checkboxes[index].toggle();
            },
            selectScenes() {
                this.showScenes = true;
                this.result=[];
                this.yxresult.forEach(item=>{
                    this.result.push(item);
                })
        },
    },
           
}

</script>

<style scoped lang="scss">
::v-deep{
    .van-icon-star{font-size: 0.5rem!important;}
    .van-uploader__upload, .van-uploader__preview-image{border: 2px solid #eee;border-radius: 0.2rem;}
    .rec-screen-xingzuo{
        .van-picker__columns{display:none;}
    }
}
    .follow .tabs-content{
        background-color: #fff;
        margin-top: 0.2rem;
    }
    .search-haed{
        width: 100%;
        height: 1rem;
        background-color: #fff;
        .search{
            margin: 0 auto;
            width: 90%;padding: 0.4rem 0;
            input{
                width: 100%;
                padding: 0.1rem 0.6rem;
                padding-right:0.8rem ;
                border: 1px solid #ccc;
                border-radius: 0.3rem;
                background-color: #ccc;
            }
            .iconfont{
                position: absolute;
                top: 0.5rem;
                left:7%;
            }
            .chongzhi{
                position: absolute;
                top: 0.5rem;
                right: 7%;
                z-index: 2;
            }
        }
    }
    .Loading{
        text-align: center;
        margin-top: 0.1rem;
        color: #ccc;
    }
    .cc{
        margin-right: 0.2rem;
    }
    .tt{
        text-align: right;
    }


</style>