<template>
    <div class="follow">
        <div class="list-box"  v-for="(item,index) in List" :key="index">
            <div class="headimg">
                <img :src="item.userinfo.avatar"/>
            </div>
            <div class="info-boxs">
                <div class="info-box">
                    <div class="info-one">
                        <span class="Ellipses1 name">{{item.userinfo.nickname}}</span>
                        <span class="level">{{item.userinfo.rank_name}}</span>
                        <span class="xinxin"><img :src="require('@assets/images/grade.png')" /></span>
                        <span class="repx">酒量值:{{item.userinfo.exppr}}</span>
                    </div>
                    <div class="info-two">
                        <span>粉丝{{item.userinfo.follow_number}}</span>
                        <span>内容{{item.userinfo.content}}</span>
                        <span class="city-box">{{item.userinfo.constellation}}|{{item.userinfo.city}}</span>
                    </div>
                </div>
                <!-- <div class="bottom-box">
                    <div  :class="'bottom-bit bottom-bit'+item.is_follow" @click="is_follow(item,item.is_follow)">{{item.is_follow==2?'互相关注':(item.is_follow?'取消关注':'关注')}}</div>
                </div> -->
                <FollowBit :arr="item" :follow="item.is_follow"></FollowBit>
            </div>
        </div>
    </div>
</template>
<script>
    //import { setFollow } from "@api/tasting";
    import FollowBit from '../../components/FollowBit';
    import { Icon, Toast, Tab, Tabs } from "vant";
    export default {
        name: "Follow",
        data: function() {
            return {
                onibdex:0,
            };
        },
        props: {
            List: {
                type: Array,
                default: () => []
            }
        },
        components: {
            Icon, Toast, Tab, Tabs ,FollowBit
        },
        mounted: function() {
        },
        methods: {
        //    is_follow(item,index){
        //        let mag= '';
        //        if(!index){
        //            mag="关注中";
        //            index=1;
        //        }else{
        //            index=0;
        //            mag="取消中";
        //        }
        //        Toast.loading({
        //             duration: 0,
        //             message: mag,
        //             forbidClick: true,
        //         });
        //        setFollow({
        //            uid:item.uid,
        //            to_uid:item.to_uid
        //        }).then(res=>{
        //             Toast.clear();
        //             this.$set(item,'is_follow',index);
        //        }).catch(err => {
        //         Toast.fail({
        //             message: err.msg,
        //             forbidClick: true,
        //         });
        //     });
              
        //    }
        }
    }
</script>
<style lang="scss">
    .follow .tabs-content .list-box .headimg{
        width: 40%;
        text-align: center;
    }
    .follow .info-boxs{
        display: flex;
        width: 85%;
        border-bottom: 1px solid #eee;
    }
    .follow .tabs-content .info-box{
        max-width: 77%;
        width: 77%;
    }
    .follow .tabs-content .list-box{
        display: flex;
        .headimg{
            width: 1.2rem;
            height: 1.2rem;
            padding: 0.1rem;
            img{
                width: 100%;
                height: 100%;
                border-radius: 1rem;
            }  
        }
        .info-box .info-one {
            display: flex;
            padding: 0.1rem;
            margin-top: 0.1rem;
            align-items: center;
            span{
              margin: 0 0.06rem;
            } 
            .name{
                font-weight: 600;
                font-size: 0.3rem;
                color: #4b6886;
                max-width: 20%;
            }
            .level{
                background-color: #f00;
                color: #fff;
                font-size: 0.2rem;
                padding:0.02rem 0.2rem; 
            }
            .xinxin{
                line-height: 0.1rem;
                img{
                width: 0.38rem;
                height: 0.38rem; 
                }
            }
            .repx{
                font-size: 0.2rem;
                color: #f00;
            }
            
        }
     }
    // .tabs-content .bottom-box{
    //     display: flex;
    //     align-items: center;
    //     justify-content:center;
    //     .bottom-bit{
    //         padding: 0.08rem 0.2rem;
    //         border-radius: 0.5rem;
    //         border: 1px solid #e0e0e0;
    //         color: #ccc;
    //     }
    //     .bottom-bit0{
    //          padding: 0.08rem 0.3rem;
    //         background-color: #f00;
    //         border: 1px solid #f00;
    //         color: #fff;
    //     }
    //     .bottom-bit1,.bottom-bit2{
    //         border: 1px solid #e0e0e0;
    //         color: #ccc;
    //         font-size: 0.2rem;
    //     }
    // }
    
    .follow .tabs-content .info-box span img{
            width: 100%;
            height: 100%;
    }
    .follow .tabs-content .info-box .info-two{
        font-size: 0.25rem;
        font-weight: 400;
        padding: 0 0.1rem;
        span{
            margin-left: 0.1rem;
            color: #767676;
        }
        .city-box{
            font-weight: 800;
            padding-left: 0.2rem;
        }
    }
    
    
</style>